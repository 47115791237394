.realm {
  &--button-group {
    width: 100%;
    button{
      width: 50%;
      border: 1px solid $concrete;
      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }
  }
}
