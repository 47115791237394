.realm {
  &--main-footer {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    text-align: center;
    border-top: 1px solid #666;
  }
  &--main-footer-wrapper {
    width: 100%;
  }
  &--main-footer-links {
    margin-bottom: 1rem;
    a {
      display: inline-block;
      margin: 0 .75rem;
      font-family: $font-family-cormorant;
      color: #fff;
       &:hover {
        color: #6cc0e5;
      }
    }
  }
  &--copyright {
    font-size: 1rem;
    color: #ddd;
  }
}

.mobile {
  .realm {
    &--main-footer {
      padding-bottom: 8rem;
    }
  }
}
