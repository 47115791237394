.realm {
  &--contact-link {
    float: right;
    color: #333;
    margin-top: 36px;
    &:hover {
      color: #666;
      text-decoration: none;
    }
  }
  &--house-card {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    background-color: rgba(61, 67, 98, 0.8);
    border-top: 1px solid $leather;
  }
  &--house-card-image {
    flex-basis: 100%;
    img {
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      background-size: 100% auto !important;
      background-repeat: no-repeat !important;
      transition: background-size 0.5s ease;
      &:hover {
        background-size: 110% auto !important;
        transition: background-size 0.5s ease;
      }
    }
  }
  &--house-card-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &--house-card-title {
    font-size: 14px;
    font-weight: 400;
    color: #333;
  }
  &--house-card-address {
    font-size: 12px;
    color: #333;
  }
  &--house-card-address-block {
    flex: 1 0 50%;
    padding: 12px;
    font-size: 12px;
    line-height: 1.3;
  }
  &--house-card-broker-info {
    flex: 1 0 50%;
    padding: 12px;
    font-size: 12px;
    text-align: right;
  }
  &--house-card-chart-block {
    float: right;
    margin-right: 2px;
  }
  &--house-card-pie-block {
    flex: 1 0 25%;
    padding: 12px;
    font-size: 11px;
    text-align: right;
    overflow: hidden;
    max-height: 80px;
  }
  &--house-card-pie-bar-area {
    position: relative;
    .recharts-wrapper {
      position: relative;
      width: 100px;
      height: 40px;
      left: 50%;
      margin-left: -53px;
    }
  }
  &--house-card-total {
    padding: 3px;
    background: rgba(0,0,0,0.5);
    border-radius: 5px;
  }
  &--house-card-detail-link {
    display: block;
    width: 100%;
    color: #fff;
    &:hover {
      color: #666;
      text-decoration: none;
    }
  }
  &--house-card-details-link {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #fff;
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    text-shadow: 1px 1px 0px rgba(0,0,0,.75);
  }
  &--agent-card {
    display: inline-block;
    width: 25%;
    height: 168px;
    min-width: 280px;
    margin-right: 2px;
    margin-top: 18px;
    padding: 12px;
    background-color: rgba(61, 67, 98, 0.8);
    border-top: 1px solid $leather;
    font-size: 18px;
    font-weight: 400;
  }
  &--agent-details {
    display: inline-block;
    font-size: 14px;
    vertical-align: middle;
    max-width: 190px;
  }
  &--agent-brokerage {
    font-size: 11px;
  }
  &--card-content {
    margin-top: 12px;
  }
  &--chart-block {
    float: left;
    margin-left: 4px;
    font-size: 14px;
  }
  &--total {
    position: relative;
    top: 12px;
    float: right;
    margin-right: 16px;
    font-size: 14px;
    text-align: center;
  }
  &--alert-card-title {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    padding-top: 12px;
  }
  &--alert-card-date {
    text-align: center;
    font-size: 11px;
    font-weight: 300;
    padding-top: 6px;
    padding-bottom: 12px;
  }
  &--alert-card-offered {
    text-align: center;
    line-height: 20px;
    margin: 12px auto;
    border-top: solid 1px #666;
    padding-top: 8px;
    font-weight: 300;
  }
  &--alert-card-from-agent {
    padding-top: 8px;
    font-weight: 400;
  }
}
