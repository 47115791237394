.realm {
  &--dashboard {
    margin: 48px;
    @media (max-width: 767px) {
      margin: 16px;
    }
  }
  &--dashboard-profile {
    height: 312px;
    margin-bottom: 18px;
    padding-top: 18px;
    font-size: 18px;
    text-align: center;
    background: rgba(61, 67, 98, 0.8);
    border-top: 1px solid $leather;
  }
  &--dashboard-data {
    height: 300px;
    margin-bottom: 18px;
    padding-top: 18px;
    font-size: 18px;
    text-align: center;
    background: rgba(61, 67, 98, 0.8);
    border-top: 1px solid $leather;
  }
  &--dashboard-extra {
    background: rgba(61, 67, 98, 0.8);
    border-top: 1px solid $leather;
    margin-bottom: 18px;
    padding: 18px 0;
    text-align: center;
    font-size: 18px;
  }
  &--dashboard-big-number {
    font-size: 48px;
    margin: 23px 0;
  }
  &--dashboard-blog {
    min-height: 600px;
    margin-bottom: 24px;
    padding-top: 18px;
    font-size: 18px;
    text-align: center;
    background: rgba(61, 67, 98, 0.8);
    border-top: 1px solid $leather;
  }
  &--dashboard-post-title {
    @media (max-width: 767px) {
      font-size: 28px;
    }
  }
  &--dashboard-actions {
    margin-bottom: 24px;
    font-size: 18px;
    text-align: left;
  }
  &--dashboard-actions-button {
    display: block;
    margin-bottom: 8px;
    padding: 12px;
    width: 170px;
    color: #eee;
    font-size: 11px;
    letter-spacing: 0.5;
    background-color: #72799a;
    border-radius: 4px;
    text-align: center;
    text-transform: uppercase;
    &:hover {
      background-color: $leather;
      text-decoration: none;
      color: #fff;
    }
  }
  &--complete-profile-link {
    display: block;
    font-size: 13px;
    margin-top: 15px;
    text-align: center;
  }
  &--profile-picture {
    position: relative;
    height: 140px;
    width: 140px;
    overflow: hidden;
    border-radius: 50%;
    &.centered {
      margin: 0 auto;
    }
    &.sm {
      height: 70px;
      width: 70px;
    }
    &.xs {
      width: 29px;
      height: 29px;
      margin-bottom: 0;
      margin-right: 10px;
    }
    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
  }
}

.edit-item {
  width: 400px;
  height: 200px;
}
.float-right {
  float: right;
}
.create-dialog {
  width: 400px;
}

.tinyAvatarCircle {
  position: relative;
  overflow: hidden;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  margin-right: 10px;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
