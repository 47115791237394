.profileSection {
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 12px;
}

.profileLogoEmpty {
  width: 200px;
  height: 200px;
  margin: 12px;
  border: 1px dashed gray;
  border-radius: 5px;
  text-align: center;
  padding-top: 80px;
}

.profileSpecialties {
  margin-top: 12px;
  width: 250px;
  height: 250px;
  overflow: scroll;
}

.profileDescription {
  margin-top: 12px;
  width: 250px;
}
