html,
body {
  height: 100%;
  width: 100%;
  background: #000;
  font-size: 62.5%;
}

body {
  font-family: $font-family-roboto;
  font-size: 14px;
}
a {
  text-decoration: none;
  color: #fff;
  &:hover {
    text-decoration: none;
    color: $concrete!important;
  }
  &:active,
  &:focus,
  &:visited {
    text-decoration: none;
    outline: none;
    color: inherit;
  }
}
p,
label {
  line-height: 1.5;
}

strong {
  font-family: inherit;
  font-weight: 600;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

.realm {
  &--checkbox-container {
    width: 100%;
    @media screen and (min-width: 560px) {
      width: 50%;
    }
    @media screen and (min-width: 800px) {
      width: 33.33333%;
    }
    @media screen and (min-width: 1040px) {
      width: 25%;
    }
  }
}

/* bootstrap holdovers */
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
  }
  &::-moz-placeholder {
    color: #999;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: #999;
  }
  &::-webkit-input-placeholder {
    color: #999;
  }
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }
}

/* ***********************************
//
//  style scroll bars site wide
//
*********************************** */

/* width */
::-webkit-scrollbar {
  position: fixed;
  z-index: 9999999999;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #a2a0a0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(90, 89, 89);
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
