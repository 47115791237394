.realm {
  &--office-card {
    display: inline-block;
    min-width: 250px;
    margin-right: 12px;
  }
  &--office-card-title {
    font-size: 14px;
  }
  &--office-card-address {
    font-size: 12px;
  }
}
