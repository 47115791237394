.realm {
  &--my-account {
    width: 100%;
    padding: 4rem 2rem;
    overflow: hidden;
    @media (min-width: 480px) {

    }
  }
  &--my-account-header {
    margin-bottom: 4rem;
    @media (min-width: 768px) {
      width: 33%;
      margin: 0 3%;
      float: left;
    }
    @media (min-width: 960px) {
      width: 25%;
    }
  }
  &--my-account-inputs {
    max-width: 360px;
    margin: 0 auto;
    @media (min-width: 768px) {
      max-width: none;
      width: 57%;
      margin: 0 2% 4rem;
      float: left;
    }
    @media (min-width: 960px) {
      width: 65%;
    }
  }
  &--my-account-contianer {
    width: 100%;
    overflow: hidden;
  }
  &--my-account-row {
    width: 100%;
    @media (min-width: 960px) {
      &.vertical {
        width: 50%;
        float: left;
        margin-bottom: 40px;
      }
    }
  }
  &--my-account-column {
    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 -10px;
      > div {
        width: 32%;
        flex: 1;
        margin: 0 10px;
      }
    }
  }
}
