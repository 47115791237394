.searchBlock {
  position: relative;
  background: rgba(255, 255, 255, 0.15);
  font-family: $font-family-roboto;
  margin-left: 8px;
  margin-right: 16px;
  border-radius: 2px;
  display: inline-block;
  height: 25px;
}

.searchIcon {
  width: 72px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  pointer-events: none;
  justify-content: center;
}

.searchInputContainer {
position: relative;
display: inline-block;
direction: ltr;
}

.searchInput {
  width: 200px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font: inherit;
  color: inherit;
  border: 0;
  margin: 0;
  padding: 8px 8px 8px 72px;
  display: block;
  background: none;
  white-space: normal;
}

.searchInput:focus {
  width: 250px;
  border: none;
  outline:none;
}
