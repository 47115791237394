@import '../variables';

.realm-onboarding {
  position: relative;
  font-size: 16px!important;
  line-height: 1.5!important;
  min-height: 100%;
  width: 100%;
  min-width: 60em;
  padding: 32px 32px 96px;
  font-family: $font-family-roboto;
  background-color: #000;
  color: $blush;
  overflow: scroll;

  /* element overrides */
  h1, h2, h3 {
    font-family: $font-family-cormorant;
    font-weight: 500;
    font-style: italic;
    line-height: 1.1;
    color: $blush;
    margin: 0;
  }
  h4 {
    font-family: $font-family-roboto;
    font-size: 1.25em;
    font-weight: 500;
    &.lg {
      font-size: 1.75em;
    }
  }
  p {
    font-size: 16px;
  }
  a {
     color: $blush;
     text-decoration: underline;
     cursor: pointer;
     &:hover {
       color: darken($blush, 10%)!important;
       text-decoration: underline;
     }
  }

  &--dialog {
    /* temporary override for anchor tags in modals */
    [class*="MuiDialogContent"] {
      font-weight: 400;
      color: #ccc;
      a,
      a:hover,
      a:link,
      a:visited {
        color: $gunmetal;
        text-decoration: underline;
      }
    }
  }

  /* helpers */

  .centered {
    text-align: center;
  }

  .divider {
    margin: 0 1em;
    color: $gunmetal;
  }

  /* elements */

  &--wrapper {
    max-width: 88em;
    margin: 0 auto;
  }

  &--group {
    margin-bottom: 2em;
  }

  &--paragraph-group {
    margin: 0 1em;
  }

  &--title {
    font-size: 5em;
    &.sm {
      font-size: 2.5em;
    }
    span {
      font-family: $font-family-roboto;
      font-style: normal;
    }
  }

  &--label {
    font-size: 1.2em;
    letter-spacing: .8em;
    text-transform: uppercase;
    margin: 3em 0 2em;
    &.stepper {
      margin-top: 0;
      margin-bottom: 1em;
      text-align: center;
    }
  }

  &--welcome {
    font-size: 1.25em;
    letter-spacing: .1em;
    margin: 2em 0;
    &.sm {
      margin-top: 1em;
    }
    span {
      color: $leather;
    }
  }

  &--help {
    margin-bottom: 1em;
    p {
      font-size: .85em;
      line-height: 1.1;
      margin: 0 0 .35em;
    }
  }

  &--footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1.5em 2em;
    width: 100%;
    text-align: center;
    font-size: 12px;
    background: #000;
    border-top: 1px solid $gunmetal;
    color: $concrete;
    z-index: 10;
  }

  /* navigation */

  &--actions {
    width: 100%;
    min-width: 20em;
    padding: 1em 2em;
    button {
      width: 100%;
      max-width: 30em;
      padding: 1em 0;
      display: block;
      margin: 0 auto;
      font-size: 1em;
      letter-spacing: .3em;
    }
    &.flex-container {
      width: 75%;
      margin: 0 auto;
      button {
        + button {
          margin-left: 1em;
        }
      }
    }
  }

  &--nav {
    flex: 1;
    max-width: 10em;
  }

  &--save {
    flex: 3;
    max-width: 25em;
  }

  /* forms */

  &--form {
    margin: 2em 2em 0;
    padding: 1em 2em 0;
  }

  &--input-group {
    width: 100%;
    min-width: 20em;
    padding: 1em 2em;
    &.checkbox {
      min-width: 15em;
      width: 15em;
      margin: 0 auto;
      font-size: .85em;
      > span > span > svg {
        fill: $concrete;
      }
      &.wide {
        min-width: 30em;
        width: 30em;
      }
      input[type=checkbox] {
        margin-left: 0!important;
      }
    }
    input {
      width: 100%;
      max-width: 30em;
      padding: .75em;
      display: block;
      margin: 0 auto;
      font-size: .8em;
      border: 1px solid $concrete;
      background: lighten($concrete, 10%);
      color: #000;
      letter-spacing: 0;
      transition: all 150ms ease-in-out;
      &:focus {
        outline: 2px solid $leather;
        background: #fff;
        transition: all 150ms ease-in-out;
      }
    }
    &.left-aligned {
      padding-left: 0;
      padding-right: 0;
      .realm-onboarding--form-label,
      input {
        margin: 0;
      }
    }
  }

  &--form-label {
    text-align: left;
    width: 100%;
    max-width: 30em;
    padding: .5em 0;
    display: block;
    margin: 0 auto;
    font-size: .8em;
  }

  &--tag {
    display: inline-block;
    padding: .5em;
    font-size: .85em;
    background: $leather;
    border: 1px solid $concrete;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    +.realm-onboarding--tag {
      margin-left: .25em;
    }
  }

  &--dropzone-container {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    width: 100%;
    margin: 2em;
    padding: 2em;
  }

  &--dropzone-selector {
    flex: 1 0 20%;
  }

  &--dropzone {
    flex: 1 0 77.5%;
    margin-right: 2.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: auto;
    padding: 2em;
    min-height: 200px;
    font-size: 1.5em;
    font-weight: 500;
    text-align: center;
    border: 2px dashed $concrete;
    border-radius: 5px;
    input[type="file"] {
      width: 100%!important;
    }
  }

  &--table {
    min-height: 200px;
    margin: 2em 0;
  }

  .ReactTable {
    border: 0;
    .rt-table {
      .rt-thead {
        &.-header {
          box-shadow: none;
        }
        .rt-tr {
          text-align: left;
          border-bottom: 1px solid $concrete;
          margin-bottom: 0;
        }
      }
      .rt-tbody {
        .rt-tr-group {
          .rt-tr {
            border-bottom: 1px solid $concrete;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .rt-td {
            padding-left: 5px;
            padding-right: 1em;
            text-align: left;
          }
        }
      }
    }
  }

  &--monitor-button {
    display: block;
    position: relative;
    width: 50%;
    margin: 2em;
    opacity: 0.75;
    text-align: center;
    text-decoration: none!important;
    transition: opacity 250ms ease-in-out;
    color: #fff;
    &:hover {
      opacity: 1;
      transition: opacity 250ms ease-in-out;
      text-decoration: none!important;
      color: #fff!important;
    }
    img {
      display: block;
      height: 25em;
      margin: 0 auto 1.5em;
    }
  }

  /* grid */

  .flex-container {
    display: flex;
    padding: 0 2em;
    margin-bottom: 2em;
  }

  .flex-spaceAround {
    justify-content: space-around;
  }

  .row {
    border: 1px solid $concrete;
    border-radius: 10px;
    background: $gunmetal;
    padding: 2em 1em;
    display: flex;
    justify-content: center;
    color: #fff;
  }
  .col {
    flex: 1;
    margin: 0 1em;
    padding: 0 2em;
    + .col {
      border-left: 1px solid darken($concrete, 30%);
    }
  }
  .col-1 {
    flex: 1;
  }
  .col-2 {
    flex: 2;
  }
  .col-3 {
    flex: 3;
  }
  .col-4 {
    flex: 4;
  }

  //tags override
  .profileSpecialties {
    overflow: auto;
    height: auto;
    width: 100%;
  }

  // contact form
  &--contact-form {
    display: block;
    form {
      width: 100%;
    }
    label {
      display: block;
      width: 100%;
    }
    textarea {
      width: 100%;
      min-height: 280px;
      padding: 1em;
      margin-top: 1em;
    }
    input[type=submit] {
      display: block;
      width: 100%;
      margin: 0 auto;
      padding: 12px;
      background: $leather;
      color: #fff;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      &:hover {
        background: darken($leather, 10%);
      }
    }
  }

  &--email-sent {
    h4 {
      max-width: 20em;
      line-height: 1.2;
      span {
        color: $leather;
        text-decoration: underline;
        &:hover {
          color: darken($leather, 10%);
        }
      }
    }
  }

}

.temp-logout {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999999999999;
  background: yellow;
  color: black;
  width: 150px;
  height: 24px;
  font-size: 10px;
  text-align: center;
  padding: 6px;
  cursor: pointer;
}
