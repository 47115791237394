button.edit, button.edit:focus {
 color: #fff !important;
 background-color: transparent !important;
 border: none;
}

button.edit:hover {
  color: #88e !important;
}
.json-input {
  height: 300px;
  width: 100%;
}
.edit-modal {
  width: 400px;
  padding: 20px;
  color: #ccc;
  line-height: 20px;
}
.input-error {
  background: pink;
  border: 1px solid red;
}
