.header {
  img {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
  .nav-bar {
    text-align: center;
  }
  .router-link {
    display: inline-flex;
    margin: 1em;
    padding: 0.25em 2em;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    outline: 0;
    text-decoration: none;
    border: 2px solid #41addd;
    border-radius: 4px;
    color: #41addd;
    cursor: pointer;
    user-select: none;
    -webkit-touch-callout: none;
    &:active {
      background: #41addd;
      color: #fff;
    }
  }
}
