.realm {
  &--not-found-page {
    h1 {
      font-size: 2em;
      margin-bottom: 0.25em;
    }
  }
  &--supported-browser-list {
    padding: 20px;
    font-size: 32px;
    line-height: 1;
    p {
      margin: 10px;
    }
  }
  &--page404 {
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    padding: 200px;
  }
  &--message404 {
    font-size: 46px;
    margin-top: 10px;
    margin-bottom: 56px;
    font-family: $font-family-cormorant;
    text-transform: none;
  }
  &--button404 {
    background-color: rgb(165, 122, 65);
    padding: 12px 60px;
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    &:hover {
      color: #000;
      text-decoration: none;
    }
  }

  &--no-records-found {
    width: 100%;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &--no-records-found-message {
    width: 320px;
    padding: 20px;
    font-size: 16px;
    line-height: 1.7;
    text-align: center;
    background: rgba(0,0,0,0.85);
    color: rgba(255,255,255,0.85);
    p {
      margin: 0;
      line-height: 1.7;
    }
    strong {
      font-size: 18px;
    }
  }
}
