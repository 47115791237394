.realm {
  &--cookie-warning {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #e3e3e3;
    padding: 2rem 4rem;
    font-family: $font-family-roboto;
    font-size: 16px;
    text-align: center;
    z-index: 100;
  }
  &--cookie-warning-actions {
    button {
      display: block;
      width: 280px;
      margin: 0 auto;
    }
  }
}
