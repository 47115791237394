.tagStyle {
  font-size: 12px;
  background: #bbe;
  border-radius: 11px;
  transition: all 0.5s ease;
  width: fit-content;
  display: inline-block;
  margin-right: 8px;
  &:hover {
    background: #fff;
    transition: all 0.5s ease;
  }
}
.tagLabel {
  padding: 5px;
  padding-bottom: 4px;
  padding-left: 10px;
  color: #333;
  float: left;
}
.tagAction {
  float: right;
  border-left: 1px solid #aba8a8;
  font-size: 16px;
  padding: 2px 4px;
  margin-left: 6px;
}

.realm {
  &--tag-dialog-container {
    position: relative;
    color: #fff;
  }
  &--no-tags-container {
    margin: 3rem 0;
    padding: 3rem;
    font-size: 1.75rem;
    text-align: center;
    background: #303d3b;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    p {
      margin: 0;
    }
  }
  &--edit-tags-toggle {
    display: block;
    width: 100%;
    padding: 1rem;
    text-align: center;
    text-transform: uppercase;
    background: $leather;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    &:hover {
      background: darken($leather, 5%);
      color: #fff!important;
      transition: all 250ms ease-in-out;
    }
  }
  &--tag-list-label {
    font-size: 1.2rem;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(245, 224, 210, .45);
    margin-top: 4rem;
    padding-bottom: 1rem;
    color: $concrete;
    &:first-child {
      margin-top: 0;
    }
  }
  &--tag-list-tag {
    display: block;
    width: 100%;
    font-size: 1.5rem;
    line-height: 1;
    margin: 0;
    padding-bottom: 1rem;
    padding: 2rem 0;
    border-bottom: 1px solid rgba(245, 224, 210, .45);
  }
  &--tag-list-selectable-tag {
    display: block;
    width: 100%;
    font-size: 1.5rem;
    line-height: 1;
    margin: 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(245, 224, 210, .45);

    input[type="checkbox"] {
      opacity: 0;
    }

    label  {
      position: relative;
      width: 100%;
      color: $sand;
      transition: all 250ms ease-in-out;
      cursor: pointer;
      &:before {
        position: absolute;
        top: 0;
        right: 0;
        content: "";
        display: inline-block;
        height: 24px;
        width: 24px;
        border: 1px solid rgba(245, 224, 210, .7);
        border-radius: 50%;
        background: rgba(245, 224, 210, .25);
        transition: all 250ms ease-in-out;
      }
      &:after {
        position: absolute;
        right: 9px;
        top: 9px;
        content: "";
        display: inline-block;
        height: 1px;
        width: 1px;
        border-left: 1px solid $leather;
        border-bottom: 1px solid $leather;
        transform: rotate(-45deg);
        transition: all 250ms ease-in-out;
      }
    }
    input[type="checkbox"] + label::after {
      transition: all 250ms ease-in-out;
    }
    input[type="checkbox"]:checked + label::after {
      content: "";
      right: 5px;
      top: 6px;
      height: 8px;
      width: 13px;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;

    }
    input[type="checkbox"]:checked + label::before {
      border: 1px solid $leather;
      background: $leather;
      transition: all 250ms ease-in-out;
    }
  }
  &--tag-action {
    float: right;
    margin-left: 6px;
    padding: 2px 4px;
    font-size: 16px;
    border-left: 1px solid #aba8a8;
  }
  &--tag-label {
    float: left;
    padding: 5px 10px 4px 5px;
    color: #333;
  }
  &--tag-style {
    display: inline-block;
    width: fit-content;
    margin-right: 8px;
    font-size: 12px;
    background: #bbe;
    border-radius: 11px;
    transition: all 0.5s ease;
    &:hover {
      background: #fff;
      transition: all 0.5s ease;
    }
  }
}
