.realm {
  &--page-title-bar {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 800;
    height: 68px;
    margin: 0;
    padding: 22px 30px;
    font-size: 16px;
    line-height: 32px;
    text-transform: uppercase;
    background-color: $leather;
    border: none;
    color: #fff;
    @media screen and (min-width: 600px) {
      transform: translateY(10px);
    }
    a {
      color: #fff;
      &:hover {
        color: #e2d3f3;
        text-decoration: none;
      }
    }
  }
  &--add-plus {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 32px;
    &:hover {
      color: #bbe;
      transition: all 0.5s ease;
    }
    > i {
      margin-right: 0;
    }
  }
}

// because scrollbar is going
// behind these elements

@media screen and (-webkit-min-device-pixel-ratio:0) {
  .show-scrollbar {
    header {
      @media screen and (min-width: 1048px) {
        width: calc(100% - 5px);
        left: 0;
      }
    }
  }
}
