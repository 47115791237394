#app, #wrapper {
  height: 100%;
  width: 100%;
  margin-bottom: 0;
  background: #000;
}

#page-wrapper {
  padding: 53px 0 0 0 !important;
  background: #343548 !important;
  color: #eee;
  overflow: auto;
  height: 100vh;
}

.page-with-title {
  position: relative;
  padding-top: 70px;
  height: 100%;
}

.page-content {
  min-height: 70vh;
  margin-bottom: 0;
  margin-left: 0;
}

.realm--page-for-copy {
  margin: 64px;
}

/* ***********************************
//
// material-ui for Realm
//
*********************************** */

.MuiButton-textPrimary-2073 {
  color: #dbdde9 !important;
}

.MuiDefault {
  color: #ccc !important;
}

.MuiDefault:hover {
  background-color: rgba(200, 200, 200, 0.1) !important;
}

.input250 {
  min-Width: 250px !important;
}

.outlineTextArea {
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid #bbb;
}

/* brokerage address subcomponent */
.brokerage-address,
.editArea {
  padding-left: 40px;
  margin-bottom: 12px;
}

.editTextField {
  margin-left: 12px;
  margin-right: 12px;
  width: 200px;
  color: #fff;
}

.snackX {
  font-size: 16px !important;
}
