@import '../variables';

.realm {
  &--legend-container {
    padding: .5rem 1rem;
    overflow: hidden;
    position: relative;
    width: 100%;
    @media screen and (min-width: 640px) {
      padding: 2.5rem 1rem .5rem;
    }
  }
  &--legend {
    float: right;
    margin: 1rem 0;
    padding: 0;
    list-style: none;
    li {
      margin: 1rem 12rem 1rem 1rem;
      &:last-child {
        position: absolute;
        top: .5rem;
        right: -11rem;
      }
      @media screen and (min-width: 640px) {
        display: inline-block;
        margin: 0 1rem;
        &:last-child {
          position: static;
          top: auto;
          right: auto;
        }
      }
    }
  }
  &--legend-block {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #fff;
    margin-left: 4px;
    &.high {
      background: #267b26;
    }
    &.md {
      background: #b3893c;
    }
    &.low {
      background: #867b39;
    }
  }
  &--matching-title {
    width: 100%;
    text-align: center;
    color: #ddd;
    font-size: 18px;
    line-height: 1.2;
    padding: 1rem 1rem .5rem;
    @media screen and (min-width: 600px) {
      font-size: 24px;
    }
  }
  &--listed-at {
    width: 100%;
    text-align: center;
    color: #ddd;
    font-size: 14px;
    padding: 0;
  }
  &--grid-container {
    position: relative;
    padding: 2rem;
    z-index: 20;
    .recharts-responsive-container {
      display: block;
      margin: 0 auto;
    }
    &.header {
      @media screen and (min-width: 320px) and (max-width: 599px) {
        padding: 0 2rem;
      }
    }
  }
  &--matching-details {
    position: relative;
    overflow: hidden;
    height: 100%;
  }
  &--details-background {
    position: absolute;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    @media screen and (min-width: 600px) {
      height: 100%;
      padding-bottom: 0;
    }
  }
  &--details-my-matches {
    padding: 16px;
  }
  &--shadow {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    background-image: linear-gradient(rgba(0,0,0,1), transparent, transparent);
    z-index: 10;
    @media screen and (max-width: 599px) {
      height: 600px;
    }
  }
  &--client-agent-container {
    position: relative;
    font-size: 36px;
    text-align: center;
    color: #fff;
    @media screen and (min-width: 320px) and (max-width: 419px) {
      padding-bottom: 10%;
    }
    @media screen and (min-width: 420px) and (max-width: 539px) {
      padding-bottom: 20%;
    }
    @media screen and (min-width: 540px) and (max-width: 599px) {
      padding-bottom: 28%;
    }
    @media screen and (min-width: 600px) and (max-width: 959px) {
      min-height: 182px;
      padding: 2rem;
      background: rgba(0,0,0,0.4);
      border: 1px solid #444;
      border-radius: 7px;
    }
  }
  &--agents-container {
    position: relative;
    z-index: 20;
    padding: 2rem;
    border-top: 1px solid #808080;
    h4 {
      font-size: 24px;
      margin: 0;
    }
  }
  &--tag-container {
    min-height: 182px;
    position: relative;
    padding: 2rem;
    font-weight: 400;
    text-align: center;
    background: rgba(0,0,0,0.4);
    border: 1px solid #444;
    border-radius: 7px;
    h4 {
      font-size: 18px;
      text-transform: uppercase;
      margin: 0 0 1rem;
    }
  }
  &--matching-graphic {
    @media screen and (min-width: 960px) {
      order: -1;
    }
  }
  &--details-legend {
    @media screen and (min-width: 600px) {
      display: flex;
    }
  }
  &--details-legend-box {
    flex: 1;
    margin: 1rem;
    padding: 20px 8px;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    background: #808080;
    &.left {
      background: #8888ff;
    }
    &.middle {
      background: #cccfff;
    }
    &.right {
      background: #eeefff;
      color: black;
    }
  }

  &--details-my-matches {
      a {
        color: #fff !important;
        &:hover {
          color: $titlebar !important;
        }
      }
  }

}
