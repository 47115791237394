.realm {
  &--blog-post {
    color: #fff;
    font-weight: 300;
    img {
      width: 100%;
      height: auto;
    }
  }
  &--blog-post-title {
    margin-bottom: 44px;
    margin-top: -10px;
    font-family: $font-family-cormorant;
    font-size: 36px;
    font-weight: 500;
  }
  &--blog-list-panel {
    text-align: left;
    padding: 8px;
    margin-bottom: 12px;
    color: #fff;
    border-top: 1px solid #ac5f0f;
  }
  &--blog-category {
    font-family: $font-family-cormorant;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.03em;
    margin-bottom: 12px;
    text-transform: uppercase;
  }
  &--blog-title {
    font-size: 50px;
    font-weight: 500;
    color: #fff;
  }
  &--blog-list {
    margin-left: 12px;
  }
  &--blog-link {
    font-size: 14px;
    letter-spacing: 0.075em;
    line-height: 12px;
    font-weight: 300;
    margin-bottom: 8px;
  }
  &--blog-card {
    background-color: rgba(61, 67, 98, 0.8);
    margin-bottom: 16px;
  }
  &--blog-card-link {
    font-size: 16px;
    letter-spacing: 0.1em;
    line-height: 18px;
    font-weight: 300;
    padding: 12px;
  }
}
