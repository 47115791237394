.realm {
  &--logout-screen {
    width: 100%;
    height: 100%;
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #343548;
    color: $blush;

    text-align: center;
    .realm--loading-container {
      min-height: auto;
    }
    h1 {
      font-size: 5rem;
      font-family: $font-family-cormorant;
      font-style: italic;
      margin-bottom: 1rem;
    }

  }
  &--signing-out {
    margin-top: 1rem;
  }
}
