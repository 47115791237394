$varcolor1:     #41addd !default;
$varcolor2:     #6cc0e5 !default;
$varcolor3:     #363636 !default;
$varcolor4:     #53573d !default;
$varcolor5:     #ac5f0f !default;
$titlebar:      #ac5f0f !default;

$gunmetal:      #303d3b !default;
$leather:       #ac5f0f !default;
$blush:         #f5e0d2 !default;
$concrete:      #c5c3bb !default;
$sand:          #e8e5e2 !default;

$font-family-cormorant: "Cormorant", "Georgia", "Times New Roman", Times, serif !default;
$font-family-roboto:    "Roboto", "Helvetica Neue", Helvetica, Arial, serif !default;
