.realm {
  &--agentclient-card-content {
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #999;
  }
  &--agentclient-card-brokerage {
    font-size: 14px;
  }
  &--agentclient-card-info {
    font-size: 12px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #999;
  }
  &--agentclient-card-listing-btn {
    display: inline-block;
    background: $concrete;
    border-radius: 4px;
    padding: 9px 20px;
    color: #333!important;
    text-transform: uppercase;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    transition: 350ms ease;
    margin: 10px;
    &:hover {
      color: #333!important;
      background: darken($concrete, 10%);
      transition: 350ms ease;
    }
  }
  &--agentclient-card-actions {
    padding: 0 20px;
  }
}
