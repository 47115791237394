.ReactTable.-striped .rt-tr.-odd {
  padding-top:8px;
  padding-left: 8px;
  background: rgba(61, 67, 98,0.9)!important;
  margin-left: 20px;
  margin-right: 12px;
  border-left: #4e8dc3 3px solid;
  margin-bottom: 12px;
  &:hover {
    background: rgba(0, 0, 0, 0.2)!important;
  }
}

.ReactTable.-striped .rt-tr.-even {
  padding-top: 8px;
  padding-left: 8px;
  background: rgba(61, 67, 98, 0.8);
  margin-left: 20px;
  margin-right: 12px;
  border-left: #4e8dc3 3px solid;
  margin-bottom: 12px;
  &:hover {
    background: rgba(0, 0, 0, 0.2)!important;
  }
}

/* on hover */
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(200,30,30,0.4);
  background: #632703;
}

/* column headers */
.ReactTable .rt-thead .rt-tr {
  color: gray;
  font-size: 12px;
  text-transform: uppercase;
  padding-bottom:8px;
}

/* turning diamond for subcomponents */
.ReactTable .rt-expander {
  &:after {
    border-top: 10px solid hsla(0,0%,78.4%,.8)!important;
  }
}

.ReactTable .-pagination input, .ReactTable .-pagination select {
  background: none;
}

.ReactTable a {
  color: #fff;
  transition: all 0.5s ease;
}

.ReactTable a:hover {
  color: #bbc;
  color: #ac5f0f !important;
  transition: all 0.5s ease;
}

.ReactTable .-pagination .-btn {
  color: #eee;
}

.ReactTable .-pagination .-pageJump input {
  width: 54px;
  text-align: center;
}

.-totalPages {
  padding-left:12px;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer:focus, .ReactTable .rt-thead .rt-td.-cursor-pointer:focus {
  outline: none;
}

.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(90,0,0,0.6);
}

.ReactTable .rt-thead .rt-resizable-header-content {
  color: #aaa;
}

.ReactTable .rt-thead .rt-th.-sort-asc .rt-resizable-header-content::after, .ReactTable .rt-thead .rt-td.-sort-asc {
  font-size: 10px;
  content: " ▲";
}

.ReactTable .rt-thead .rt-th.-sort-desc .rt-resizable-header-content::after, .ReactTable .rt-thead .rt-td.-sort-asc {
  font-size: 10px;
  content: " ▼";
}

.ReactTable .rt-thead.-filters input, {
  background:#777;
  margin-left: 20px;
  color: white;
}

/* ***************************************************
//
// for subcomponent editing for reacttable
//
// *************************************************** */

.editSection {
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 12px;
}

.editSpecialties {
  margin-top: 12px;
  width: 250px;
}

.editDescription {
  margin-top: 12px;
  width: 250px;
}

.matchTotal {
  text-align: center;
  margin-right: 28px;
  padding-top: 14px;
  font-size: 20px;
}
