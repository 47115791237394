.realm {
  &--filters {
    width: 100%;
    padding: 1rem 2rem;

    @media screen and (min-width: 680px) {
      padding: 2rem 2rem 1rem;
      overflow: hidden;
    }
  }
  &--filter {
    position: relative;
    width: 100%;
    &.toggle {
      margin-left: -1rem;
      @media screen and (min-width: 680px) {
        width: 120px;
        height: 48px;
        .realm--legend-actions {
          position: absolute;
          top: 20px;
          left: 0;
        }
      }
    }
    &.switch {
      @media screen and (min-width: 680px) {
        width: 160px;
        height: 48px;
      }
    }
    @media screen and (min-width: 680px) {
      display: inline-block;
      width: auto;
      &.ie {
        vertical-align: top;
      }
      + .realm--filter {
        margin-right: 20px;
        margin-top: 15px;
      }
    }
  }
  &--agent-profile {
    margin-top: 12px;
    padding-bottom: 24px;
  }
  &--agent-profile-content-panel {
    padding: 8px 24px;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
  }
}
