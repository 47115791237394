@charset "UTF-8";
/* Core */
/*! sanitize.css v8.0.0 | CC0 License | github.com/csstools/sanitize.css */
/* Document
 * ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add border box sizing in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: border-box;
  /* 2 */ }

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */ }

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Use the default user interface font in all browsers (opinionated).
 * 3. Correct the line height in all browsers.
 * 4. Use a 4-space tab width in all browsers (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */
html {
  cursor: default;
  /* 1 */
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* 2 */
  line-height: 1.15;
  /* 3 */
  -moz-tab-size: 4;
  /* 4 */
  tab-size: 4;
  /* 4 */
  -ms-text-size-adjust: 100%;
  /* 5 */
  -webkit-text-size-adjust: 100%;
  /* 5 */
  word-break: break-word;
  /* 6 */ }

/* Sections
 * ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
 * ========================================================================== */
/**
 * 1. Add the correct sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * Add the correct display in IE.
 */
main {
  display: block; }

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none; }

/**
 * 1. Use the default monospace user interface font
 *    in all browsers (opinionated).
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: Menlo, Consolas, Roboto Mono, Ubuntu Monospace, Noto Mono, Oxygen Mono, Liberation Mono, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Text-level semantics
 * ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Add the correct text decoration in Edge, IE, Opera, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted; }

/**
 * 1. Use the default monospace user interface font
 *    in all browsers (opinionated).
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: Menlo, Consolas, Roboto Mono, Ubuntu Monospace, Noto Mono, Oxygen Mono, Liberation Mono, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/*
 * Remove the text shadow on text selections in Firefox 61- (opinionated).
 * 1. Restore the coloring undone by defining the text shadow
 *    in all browsers (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000;
  /* 1 */
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000;
  /* 1 */
  text-shadow: none; }

/* Embedded content
 * ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg:not([fill]) {
  fill: currentColor; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Tabular data
 * ========================================================================== */
/**
 * Collapse border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse; }

/* Forms
 * ========================================================================== */
/**
 * Inherit styling in all browsers (opinionated).
 */
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/**
 * Remove the margin in Safari.
 */
button,
input,
select {
  margin: 0; }

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge, Firefox, and IE.
 */
button {
  overflow: visible;
  /* 1 */
  text-transform: none;
  /* 2 */ }

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
 * Show the overflow in Edge and IE.
 */
input {
  overflow: visible; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Add the correct display in Edge and IE.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none; }

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 * 3. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  margin: 0;
  /* 1 */
  overflow: auto;
  /* 2 */
  resize: vertical;
  /* 3 */ }

/**
 * Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  padding: 0; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto; }

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText; }

/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge and IE.
 */
details {
  display: block; }

/*
 * Add the correct styles in Edge, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content; }

dialog:not([open]) {
  display: none; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block; }

/**
 * Add the correct display in IE.
 */
template {
  display: none; }

/* User interaction
 * ========================================================================== */
/*
 * 1. Remove the tapping delay in IE 10.
 * 2. Remove the tapping delay on clickable elements
      in all browsers (opinionated).
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation;
  /* 2 */ }

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none; }

/* Accessibility
 * ========================================================================== */
/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */
[aria-busy="true"] {
  cursor: progress; }

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */
[aria-controls] {
  cursor: pointer; }

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */
[aria-disabled="true"],
[disabled] {
  cursor: not-allowed; }

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */
[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

html,
body {
  height: 100%;
  width: 100%;
  background: #000;
  font-size: 62.5%; }

body {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, serif;
  font-size: 14px; }

a {
  text-decoration: none;
  color: #fff; }
  a:hover {
    text-decoration: none;
    color: #c5c3bb !important; }
  a:active, a:focus, a:visited {
    text-decoration: none;
    outline: none;
    color: inherit; }

p,
label {
  line-height: 1.5; }

strong {
  font-family: inherit;
  font-weight: 600; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 500; }

.realm--checkbox-container {
  width: 100%; }
  @media screen and (min-width: 560px) {
    .realm--checkbox-container {
      width: 50%; } }
  @media screen and (min-width: 800px) {
    .realm--checkbox-container {
      width: 33.33333%; } }
  @media screen and (min-width: 1040px) {
    .realm--checkbox-container {
      width: 25%; } }

/* bootstrap holdovers */
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px; }

.form-group {
  margin-bottom: 15px; }

.form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px; }

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }
  .form-control:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .form-control::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #999; }
  .form-control::-webkit-input-placeholder {
    color: #999; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }

/* ***********************************
//
//  style scroll bars site wide
//
*********************************** */
/* width */
::-webkit-scrollbar {
  position: fixed;
  z-index: 9999999999;
  width: 5px; }

/* Track */
::-webkit-scrollbar-track {
  background: #a2a0a0; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5a5959;
  border-radius: 3px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; }

.realm--serified {
  font-family: "Cormorant", "Georgia", "Times New Roman", Times, serif;
  text-transform: uppercase; }

.realm--white-text {
  color: #fff !important; }
  .realm--white-text a {
    color: #fff !important; }
    .realm--white-text a:hover {
      color: #d3d3d3 !important; }

.text-centered {
  text-align: center !important; }

/* Routes */
#app, #wrapper {
  height: 100%;
  width: 100%;
  margin-bottom: 0;
  background: #000; }

#page-wrapper {
  padding: 53px 0 0 0 !important;
  background: #343548 !important;
  color: #eee;
  overflow: auto;
  height: 100vh; }

.page-with-title {
  position: relative;
  padding-top: 70px;
  height: 100%; }

.page-content {
  min-height: 70vh;
  margin-bottom: 0;
  margin-left: 0; }

.realm--page-for-copy {
  margin: 64px; }

/* ***********************************
//
// material-ui for Realm
//
*********************************** */
.MuiButton-textPrimary-2073 {
  color: #dbdde9 !important; }

.MuiDefault {
  color: #ccc !important; }

.MuiDefault:hover {
  background-color: rgba(200, 200, 200, 0.1) !important; }

.input250 {
  min-Width: 250px !important; }

.outlineTextArea {
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid #bbb; }

/* brokerage address subcomponent */
.brokerage-address,
.editArea {
  padding-left: 40px;
  margin-bottom: 12px; }

.editTextField {
  margin-left: 12px;
  margin-right: 12px;
  width: 200px;
  color: #fff; }

.snackX {
  font-size: 16px !important; }

.realm--filters {
  width: 100%;
  padding: 1rem 2rem; }
  @media screen and (min-width: 680px) {
    .realm--filters {
      padding: 2rem 2rem 1rem;
      overflow: hidden; } }

.realm--filter {
  position: relative;
  width: 100%; }
  .realm--filter.toggle {
    margin-left: -1rem; }
    @media screen and (min-width: 680px) {
      .realm--filter.toggle {
        width: 120px;
        height: 48px; }
        .realm--filter.toggle .realm--legend-actions {
          position: absolute;
          top: 20px;
          left: 0; } }
  @media screen and (min-width: 680px) {
    .realm--filter.switch {
      width: 160px;
      height: 48px; } }
  @media screen and (min-width: 680px) {
    .realm--filter {
      display: inline-block;
      width: auto; }
      .realm--filter.ie {
        vertical-align: top; }
      .realm--filter + .realm--filter {
        margin-right: 20px;
        margin-top: 15px; } }

.realm--agent-profile {
  margin-top: 12px;
  padding-bottom: 24px; }

.realm--agent-profile-content-panel {
  padding: 8px 24px;
  font-size: 16px;
  line-height: 1.5;
  text-align: center; }

.realm--blog-post {
  color: #fff;
  font-weight: 300; }
  .realm--blog-post img {
    width: 100%;
    height: auto; }

.realm--blog-post-title {
  margin-bottom: 44px;
  margin-top: -10px;
  font-family: "Cormorant", "Georgia", "Times New Roman", Times, serif;
  font-size: 36px;
  font-weight: 500; }

.realm--blog-list-panel {
  text-align: left;
  padding: 8px;
  margin-bottom: 12px;
  color: #fff;
  border-top: 1px solid #ac5f0f; }

.realm--blog-category {
  font-family: "Cormorant", "Georgia", "Times New Roman", Times, serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.03em;
  margin-bottom: 12px;
  text-transform: uppercase; }

.realm--blog-title {
  font-size: 50px;
  font-weight: 500;
  color: #fff; }

.realm--blog-list {
  margin-left: 12px; }

.realm--blog-link {
  font-size: 14px;
  letter-spacing: 0.075em;
  line-height: 12px;
  font-weight: 300;
  margin-bottom: 8px; }

.realm--blog-card {
  background-color: rgba(61, 67, 98, 0.8);
  margin-bottom: 16px; }

.realm--blog-card-link {
  font-size: 16px;
  letter-spacing: 0.1em;
  line-height: 18px;
  font-weight: 300;
  padding: 12px; }

.profileSection {
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 12px; }

.profileLogoEmpty {
  width: 200px;
  height: 200px;
  margin: 12px;
  border: 1px dashed gray;
  border-radius: 5px;
  text-align: center;
  padding-top: 80px; }

.profileSpecialties {
  margin-top: 12px;
  width: 250px;
  height: 250px;
  overflow: scroll; }

.profileDescription {
  margin-top: 12px;
  width: 250px; }

.realm--dashboard {
  margin: 48px; }
  @media (max-width: 767px) {
    .realm--dashboard {
      margin: 16px; } }

.realm--dashboard-profile {
  height: 312px;
  margin-bottom: 18px;
  padding-top: 18px;
  font-size: 18px;
  text-align: center;
  background: rgba(61, 67, 98, 0.8);
  border-top: 1px solid #ac5f0f; }

.realm--dashboard-data {
  height: 300px;
  margin-bottom: 18px;
  padding-top: 18px;
  font-size: 18px;
  text-align: center;
  background: rgba(61, 67, 98, 0.8);
  border-top: 1px solid #ac5f0f; }

.realm--dashboard-extra {
  background: rgba(61, 67, 98, 0.8);
  border-top: 1px solid #ac5f0f;
  margin-bottom: 18px;
  padding: 18px 0;
  text-align: center;
  font-size: 18px; }

.realm--dashboard-big-number {
  font-size: 48px;
  margin: 23px 0; }

.realm--dashboard-blog {
  min-height: 600px;
  margin-bottom: 24px;
  padding-top: 18px;
  font-size: 18px;
  text-align: center;
  background: rgba(61, 67, 98, 0.8);
  border-top: 1px solid #ac5f0f; }

@media (max-width: 767px) {
  .realm--dashboard-post-title {
    font-size: 28px; } }

.realm--dashboard-actions {
  margin-bottom: 24px;
  font-size: 18px;
  text-align: left; }

.realm--dashboard-actions-button {
  display: block;
  margin-bottom: 8px;
  padding: 12px;
  width: 170px;
  color: #eee;
  font-size: 11px;
  letter-spacing: 0.5;
  background-color: #72799a;
  border-radius: 4px;
  text-align: center;
  text-transform: uppercase; }
  .realm--dashboard-actions-button:hover {
    background-color: #ac5f0f;
    text-decoration: none;
    color: #fff; }

.realm--complete-profile-link {
  display: block;
  font-size: 13px;
  margin-top: 15px;
  text-align: center; }

.realm--profile-picture {
  position: relative;
  height: 140px;
  width: 140px;
  overflow: hidden;
  border-radius: 50%; }
  .realm--profile-picture.centered {
    margin: 0 auto; }
  .realm--profile-picture.sm {
    height: 70px;
    width: 70px; }
  .realm--profile-picture.xs {
    width: 29px;
    height: 29px;
    margin-bottom: 0;
    margin-right: 10px; }
  .realm--profile-picture > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto; }

.edit-item {
  width: 400px;
  height: 200px; }

.float-right {
  float: right; }

.create-dialog {
  width: 400px; }

.tinyAvatarCircle {
  position: relative;
  overflow: hidden;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  margin-right: 10px; }
  .tinyAvatarCircle img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }

.realm--logout-screen {
  width: 100%;
  height: 100%;
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #343548;
  color: #f5e0d2;
  text-align: center; }
  .realm--logout-screen .realm--loading-container {
    min-height: auto; }
  .realm--logout-screen h1 {
    font-size: 5rem;
    font-family: "Cormorant", "Georgia", "Times New Roman", Times, serif;
    font-style: italic;
    margin-bottom: 1rem; }

.realm--signing-out {
  margin-top: 1rem; }

.realm--legend-container {
  padding: .5rem 1rem;
  overflow: hidden;
  position: relative;
  width: 100%; }
  @media screen and (min-width: 640px) {
    .realm--legend-container {
      padding: 2.5rem 1rem .5rem; } }

.realm--legend {
  float: right;
  margin: 1rem 0;
  padding: 0;
  list-style: none; }
  .realm--legend li {
    margin: 1rem 12rem 1rem 1rem; }
    .realm--legend li:last-child {
      position: absolute;
      top: .5rem;
      right: -11rem; }
    @media screen and (min-width: 640px) {
      .realm--legend li {
        display: inline-block;
        margin: 0 1rem; }
        .realm--legend li:last-child {
          position: static;
          top: auto;
          right: auto; } }

.realm--legend-block {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #fff;
  margin-left: 4px; }
  .realm--legend-block.high {
    background: #267b26; }
  .realm--legend-block.md {
    background: #b3893c; }
  .realm--legend-block.low {
    background: #867b39; }

.realm--matching-title {
  width: 100%;
  text-align: center;
  color: #ddd;
  font-size: 18px;
  line-height: 1.2;
  padding: 1rem 1rem .5rem; }
  @media screen and (min-width: 600px) {
    .realm--matching-title {
      font-size: 24px; } }

.realm--listed-at {
  width: 100%;
  text-align: center;
  color: #ddd;
  font-size: 14px;
  padding: 0; }

.realm--grid-container {
  position: relative;
  padding: 2rem;
  z-index: 20; }
  .realm--grid-container .recharts-responsive-container {
    display: block;
    margin: 0 auto; }
  @media screen and (min-width: 320px) and (max-width: 599px) {
    .realm--grid-container.header {
      padding: 0 2rem; } }

.realm--matching-details {
  position: relative;
  overflow: hidden;
  height: 100%; }

.realm--details-background {
  position: absolute;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; }
  @media screen and (min-width: 600px) {
    .realm--details-background {
      height: 100%;
      padding-bottom: 0; } }

.realm--details-my-matches {
  padding: 16px; }

.realm--shadow {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  background-image: linear-gradient(black, transparent, transparent);
  z-index: 10; }
  @media screen and (max-width: 599px) {
    .realm--shadow {
      height: 600px; } }

.realm--client-agent-container {
  position: relative;
  font-size: 36px;
  text-align: center;
  color: #fff; }
  @media screen and (min-width: 320px) and (max-width: 419px) {
    .realm--client-agent-container {
      padding-bottom: 10%; } }
  @media screen and (min-width: 420px) and (max-width: 539px) {
    .realm--client-agent-container {
      padding-bottom: 20%; } }
  @media screen and (min-width: 540px) and (max-width: 599px) {
    .realm--client-agent-container {
      padding-bottom: 28%; } }
  @media screen and (min-width: 600px) and (max-width: 959px) {
    .realm--client-agent-container {
      min-height: 182px;
      padding: 2rem;
      background: rgba(0, 0, 0, 0.4);
      border: 1px solid #444;
      border-radius: 7px; } }

.realm--agents-container {
  position: relative;
  z-index: 20;
  padding: 2rem;
  border-top: 1px solid #808080; }
  .realm--agents-container h4 {
    font-size: 24px;
    margin: 0; }

.realm--tag-container {
  min-height: 182px;
  position: relative;
  padding: 2rem;
  font-weight: 400;
  text-align: center;
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid #444;
  border-radius: 7px; }
  .realm--tag-container h4 {
    font-size: 18px;
    text-transform: uppercase;
    margin: 0 0 1rem; }

@media screen and (min-width: 960px) {
  .realm--matching-graphic {
    order: -1; } }

@media screen and (min-width: 600px) {
  .realm--details-legend {
    display: flex; } }

.realm--details-legend-box {
  flex: 1;
  margin: 1rem;
  padding: 20px 8px;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  background: #808080; }
  .realm--details-legend-box.left {
    background: #8888ff; }
  .realm--details-legend-box.middle {
    background: #cccfff; }
  .realm--details-legend-box.right {
    background: #eeefff;
    color: black; }

.realm--details-my-matches a {
  color: #fff !important; }
  .realm--details-my-matches a:hover {
    color: #ac5f0f !important; }

.realm--not-found-page h1 {
  font-size: 2em;
  margin-bottom: 0.25em; }

.realm--supported-browser-list {
  padding: 20px;
  font-size: 32px;
  line-height: 1; }
  .realm--supported-browser-list p {
    margin: 10px; }

.realm--page404 {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  padding: 200px; }

.realm--message404 {
  font-size: 46px;
  margin-top: 10px;
  margin-bottom: 56px;
  font-family: "Cormorant", "Georgia", "Times New Roman", Times, serif;
  text-transform: none; }

.realm--button404 {
  background-color: #a57a41;
  padding: 12px 60px;
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none; }
  .realm--button404:hover {
    color: #000;
    text-decoration: none; }

.realm--no-records-found {
  width: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center; }

.realm--no-records-found-message {
  width: 320px;
  padding: 20px;
  font-size: 16px;
  line-height: 1.7;
  text-align: center;
  background: rgba(0, 0, 0, 0.85);
  color: rgba(255, 255, 255, 0.85); }
  .realm--no-records-found-message p {
    margin: 0;
    line-height: 1.7; }
  .realm--no-records-found-message strong {
    font-size: 18px; }

.realm-onboarding {
  position: relative;
  font-size: 16px !important;
  line-height: 1.5 !important;
  min-height: 100%;
  width: 100%;
  min-width: 60em;
  padding: 32px 32px 96px;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, serif;
  background-color: #000;
  color: #f5e0d2;
  overflow: scroll;
  /* element overrides */
  /* helpers */
  /* elements */
  /* navigation */
  /* forms */
  /* grid */ }
  .realm-onboarding h1, .realm-onboarding h2, .realm-onboarding h3 {
    font-family: "Cormorant", "Georgia", "Times New Roman", Times, serif;
    font-weight: 500;
    font-style: italic;
    line-height: 1.1;
    color: #f5e0d2;
    margin: 0; }
  .realm-onboarding h4 {
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, serif;
    font-size: 1.25em;
    font-weight: 500; }
    .realm-onboarding h4.lg {
      font-size: 1.75em; }
  .realm-onboarding p {
    font-size: 16px; }
  .realm-onboarding a {
    color: #f5e0d2;
    text-decoration: underline;
    cursor: pointer; }
    .realm-onboarding a:hover {
      color: #ecc3a8 !important;
      text-decoration: underline; }
  .realm-onboarding--dialog {
    /* temporary override for anchor tags in modals */ }
    .realm-onboarding--dialog [class*="MuiDialogContent"] {
      font-weight: 400;
      color: #ccc; }
      .realm-onboarding--dialog [class*="MuiDialogContent"] a,
      .realm-onboarding--dialog [class*="MuiDialogContent"] a:hover,
      .realm-onboarding--dialog [class*="MuiDialogContent"] a:link,
      .realm-onboarding--dialog [class*="MuiDialogContent"] a:visited {
        color: #303d3b;
        text-decoration: underline; }
  .realm-onboarding .centered {
    text-align: center; }
  .realm-onboarding .divider {
    margin: 0 1em;
    color: #303d3b; }
  .realm-onboarding--wrapper {
    max-width: 88em;
    margin: 0 auto; }
  .realm-onboarding--group {
    margin-bottom: 2em; }
  .realm-onboarding--paragraph-group {
    margin: 0 1em; }
  .realm-onboarding--title {
    font-size: 5em; }
    .realm-onboarding--title.sm {
      font-size: 2.5em; }
    .realm-onboarding--title span {
      font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, serif;
      font-style: normal; }
  .realm-onboarding--label {
    font-size: 1.2em;
    letter-spacing: .8em;
    text-transform: uppercase;
    margin: 3em 0 2em; }
    .realm-onboarding--label.stepper {
      margin-top: 0;
      margin-bottom: 1em;
      text-align: center; }
  .realm-onboarding--welcome {
    font-size: 1.25em;
    letter-spacing: .1em;
    margin: 2em 0; }
    .realm-onboarding--welcome.sm {
      margin-top: 1em; }
    .realm-onboarding--welcome span {
      color: #ac5f0f; }
  .realm-onboarding--help {
    margin-bottom: 1em; }
    .realm-onboarding--help p {
      font-size: .85em;
      line-height: 1.1;
      margin: 0 0 .35em; }
  .realm-onboarding--footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1.5em 2em;
    width: 100%;
    text-align: center;
    font-size: 12px;
    background: #000;
    border-top: 1px solid #303d3b;
    color: #c5c3bb;
    z-index: 10; }
  .realm-onboarding--actions {
    width: 100%;
    min-width: 20em;
    padding: 1em 2em; }
    .realm-onboarding--actions button {
      width: 100%;
      max-width: 30em;
      padding: 1em 0;
      display: block;
      margin: 0 auto;
      font-size: 1em;
      letter-spacing: .3em; }
    .realm-onboarding--actions.flex-container {
      width: 75%;
      margin: 0 auto; }
      .realm-onboarding--actions.flex-container button + button {
        margin-left: 1em; }
  .realm-onboarding--nav {
    flex: 1;
    max-width: 10em; }
  .realm-onboarding--save {
    flex: 3;
    max-width: 25em; }
  .realm-onboarding--form {
    margin: 2em 2em 0;
    padding: 1em 2em 0; }
  .realm-onboarding--input-group {
    width: 100%;
    min-width: 20em;
    padding: 1em 2em; }
    .realm-onboarding--input-group.checkbox {
      min-width: 15em;
      width: 15em;
      margin: 0 auto;
      font-size: .85em; }
      .realm-onboarding--input-group.checkbox > span > span > svg {
        fill: #c5c3bb; }
      .realm-onboarding--input-group.checkbox.wide {
        min-width: 30em;
        width: 30em; }
      .realm-onboarding--input-group.checkbox input[type=checkbox] {
        margin-left: 0 !important; }
    .realm-onboarding--input-group input {
      width: 100%;
      max-width: 30em;
      padding: .75em;
      display: block;
      margin: 0 auto;
      font-size: .8em;
      border: 1px solid #c5c3bb;
      background: #dcdbd7;
      color: #000;
      letter-spacing: 0;
      transition: all 150ms ease-in-out; }
      .realm-onboarding--input-group input:focus {
        outline: 2px solid #ac5f0f;
        background: #fff;
        transition: all 150ms ease-in-out; }
    .realm-onboarding--input-group.left-aligned {
      padding-left: 0;
      padding-right: 0; }
      .realm-onboarding--input-group.left-aligned .realm-onboarding--form-label,
      .realm-onboarding--input-group.left-aligned input {
        margin: 0; }
  .realm-onboarding--form-label {
    text-align: left;
    width: 100%;
    max-width: 30em;
    padding: .5em 0;
    display: block;
    margin: 0 auto;
    font-size: .8em; }
  .realm-onboarding--tag {
    display: inline-block;
    padding: .5em;
    font-size: .85em;
    background: #ac5f0f;
    border: 1px solid #c5c3bb;
    border-radius: 10px;
    color: #fff;
    cursor: pointer; }
    .realm-onboarding--tag + .realm-onboarding--tag {
      margin-left: .25em; }
  .realm-onboarding--dropzone-container {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    width: 100%;
    margin: 2em;
    padding: 2em; }
  .realm-onboarding--dropzone-selector {
    flex: 1 0 20%; }
  .realm-onboarding--dropzone {
    flex: 1 0 77.5%;
    margin-right: 2.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: auto;
    padding: 2em;
    min-height: 200px;
    font-size: 1.5em;
    font-weight: 500;
    text-align: center;
    border: 2px dashed #c5c3bb;
    border-radius: 5px; }
    .realm-onboarding--dropzone input[type="file"] {
      width: 100% !important; }
  .realm-onboarding--table {
    min-height: 200px;
    margin: 2em 0; }
  .realm-onboarding .ReactTable {
    border: 0; }
    .realm-onboarding .ReactTable .rt-table .rt-thead.-header {
      box-shadow: none; }
    .realm-onboarding .ReactTable .rt-table .rt-thead .rt-tr {
      text-align: left;
      border-bottom: 1px solid #c5c3bb;
      margin-bottom: 0; }
    .realm-onboarding .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr {
      border-bottom: 1px solid #c5c3bb;
      display: flex;
      justify-content: center;
      align-items: center; }
    .realm-onboarding .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-td {
      padding-left: 5px;
      padding-right: 1em;
      text-align: left; }
  .realm-onboarding--monitor-button {
    display: block;
    position: relative;
    width: 50%;
    margin: 2em;
    opacity: 0.75;
    text-align: center;
    text-decoration: none !important;
    transition: opacity 250ms ease-in-out;
    color: #fff; }
    .realm-onboarding--monitor-button:hover {
      opacity: 1;
      transition: opacity 250ms ease-in-out;
      text-decoration: none !important;
      color: #fff !important; }
    .realm-onboarding--monitor-button img {
      display: block;
      height: 25em;
      margin: 0 auto 1.5em; }
  .realm-onboarding .flex-container {
    display: flex;
    padding: 0 2em;
    margin-bottom: 2em; }
  .realm-onboarding .flex-spaceAround {
    justify-content: space-around; }
  .realm-onboarding .row {
    border: 1px solid #c5c3bb;
    border-radius: 10px;
    background: #303d3b;
    padding: 2em 1em;
    display: flex;
    justify-content: center;
    color: #fff; }
  .realm-onboarding .col {
    flex: 1;
    margin: 0 1em;
    padding: 0 2em; }
    .realm-onboarding .col + .col {
      border-left: 1px solid #7d796a; }
  .realm-onboarding .col-1 {
    flex: 1; }
  .realm-onboarding .col-2 {
    flex: 2; }
  .realm-onboarding .col-3 {
    flex: 3; }
  .realm-onboarding .col-4 {
    flex: 4; }
  .realm-onboarding .profileSpecialties {
    overflow: auto;
    height: auto;
    width: 100%; }
  .realm-onboarding--contact-form {
    display: block; }
    .realm-onboarding--contact-form form {
      width: 100%; }
    .realm-onboarding--contact-form label {
      display: block;
      width: 100%; }
    .realm-onboarding--contact-form textarea {
      width: 100%;
      min-height: 280px;
      padding: 1em;
      margin-top: 1em; }
    .realm-onboarding--contact-form input[type=submit] {
      display: block;
      width: 100%;
      margin: 0 auto;
      padding: 12px;
      background: #ac5f0f;
      color: #fff;
      border-radius: 4px;
      border: none;
      cursor: pointer; }
      .realm-onboarding--contact-form input[type=submit]:hover {
        background: #7d450b; }
  .realm-onboarding--email-sent h4 {
    max-width: 20em;
    line-height: 1.2; }
    .realm-onboarding--email-sent h4 span {
      color: #ac5f0f;
      text-decoration: underline; }
      .realm-onboarding--email-sent h4 span:hover {
        color: #7d450b; }

.temp-logout {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999999999999;
  background: yellow;
  color: black;
  width: 150px;
  height: 24px;
  font-size: 10px;
  text-align: center;
  padding: 6px;
  cursor: pointer; }

.realm--property-labels {
  color: #fff; }

.realm--my-account {
  width: 100%;
  padding: 4rem 2rem;
  overflow: hidden; }

.realm--my-account-header {
  margin-bottom: 4rem; }
  @media (min-width: 768px) {
    .realm--my-account-header {
      width: 33%;
      margin: 0 3%;
      float: left; } }
  @media (min-width: 960px) {
    .realm--my-account-header {
      width: 25%; } }

.realm--my-account-inputs {
  max-width: 360px;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .realm--my-account-inputs {
      max-width: none;
      width: 57%;
      margin: 0 2% 4rem;
      float: left; } }
  @media (min-width: 960px) {
    .realm--my-account-inputs {
      width: 65%; } }

.realm--my-account-contianer {
  width: 100%;
  overflow: hidden; }

.realm--my-account-row {
  width: 100%; }
  @media (min-width: 960px) {
    .realm--my-account-row.vertical {
      width: 50%;
      float: left;
      margin-bottom: 40px; } }

@media (min-width: 768px) {
  .realm--my-account-column {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -10px; }
    .realm--my-account-column > div {
      width: 32%;
      flex: 1;
      margin: 0 10px; } }

/* Global Components */
.header img {
  display: block;
  width: 100%;
  margin: 0 auto; }

.header .nav-bar {
  text-align: center; }

.header .router-link {
  display: inline-flex;
  margin: 1em;
  padding: 0.25em 2em;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  outline: 0;
  text-decoration: none;
  border: 2px solid #41addd;
  border-radius: 4px;
  color: #41addd;
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none; }
  .header .router-link:active {
    background: #41addd;
    color: #fff; }

.realm--main-footer {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  text-align: center;
  border-top: 1px solid #666; }

.realm--main-footer-wrapper {
  width: 100%; }

.realm--main-footer-links {
  margin-bottom: 1rem; }
  .realm--main-footer-links a {
    display: inline-block;
    margin: 0 .75rem;
    font-family: "Cormorant", "Georgia", "Times New Roman", Times, serif;
    color: #fff; }
    .realm--main-footer-links a:hover {
      color: #6cc0e5; }

.realm--copyright {
  font-size: 1rem;
  color: #ddd; }

.mobile .realm--main-footer {
  padding-bottom: 8rem; }

#realmDrawer .sidebaricon {
  width: 24px;
  margin-left: 2px;
  margin-right: 24px;
  font-size: 24px;
  text-align: center;
  color: #ccc !important; }
  #realmDrawer .sidebaricon:hover {
    color: #fff !important; }

#realmDrawer .subsidebaricon {
  margin-left: 15px;
  margin-right: 12px !important;
  font-size: 14px;
  color: #fff !important; }

#realmDrawer .submenuicon {
  margin-right: 8px; }

#realmDrawer .sidebarMainLink {
  font-size: 18px;
  color: #ccc; }
  #realmDrawer .sidebarMainLink:hover {
    color: #fff !important; }

#realmDrawer span {
  color: #ccc !important; }

/* *********************************
sidebar (left) navigation and styles
only for views greater than 800px
******************************** */
@media screen and (min-width: 800px) {
  .sidebar-desktop {
    display: block;
    height: 100vH;
    width: 3.75rem;
    width: 200px;
    position: fixed;
    left: 0;
    top: 52px;
    box-shadow: 1px 0 4px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0.0625rem 0 0.25rem 0 rgba(0, 0, 0, 0.25);
    z-index: 50000;
    overflow: hidden;
    transition: width .5s ease; }
  .sidebar-nav {
    background: #000; }
  .sidebar-nav .panel-title {
    height: 22px; }
  .sidebarlink {
    padding-left: 16px;
    opacity: 1;
    display: inline-block;
    width: auto;
    height: auto; }
  .sidebarlink-2nd {
    font-size: 14px;
    line-height: 32px;
    padding-left: 6px; }
  .sidebarbadge {
    position: relative;
    font-size: 11px;
    padding: 0px 3px;
    top: -2px;
    left: 24px; }
  /*** showleft: open sidebar **********/
  #wrapper.showleft .sidebar-desktop {
    width: 200px; }
  #wrapper.showleft .navpanel {
    width: 210px;
    margin: auto;
    max-height: 100vh;
    overflow-y: auto; }
  /* *** minleft: when sidebar minimized but visible ***/
  #wrapper.minleft .sidebarlink {
    opacity: 0;
    white-space: nowrap;
    width: 0px; }
  #wrapper.minleft.sidebarlink .sidebarlink-2nd {
    opacity: 0;
    white-space: nowrap;
    width: 0; }
  #wrapper.minleft .sidebarbadge {
    position: relative;
    top: -23px;
    left: 16px; }
  #wrapper.minleft .sidebar-desktop {
    width: 70px; }
  #wrapper.minleft .navpanel {
    width: 68px;
    margin-left: -4px;
    text-align: center; }
  /** hideleft: when sidebar hidden, but showing open widget **/
  #wrapper.hideleft .sidebar-desktop {
    width: 0; }
  #wrapper .sidebar-visible {
    margin-left: 220px; }
  #wrapper .sidebar-hidden {
    margin-left: 0px; } }

.searchBlock {
  position: relative;
  background: rgba(255, 255, 255, 0.15);
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, serif;
  margin-left: 8px;
  margin-right: 16px;
  border-radius: 2px;
  display: inline-block; }

.searchIcon {
  width: 72px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  pointer-events: none;
  justify-content: center; }

.searchInputContainer {
  position: relative;
  display: inline-block;
  direction: ltr; }

.searchInput {
  width: 200px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font: inherit;
  color: inherit;
  border: 0;
  margin: 0;
  padding: 8px 8px 8px 72px;
  display: block;
  background: none;
  white-space: normal; }

.searchInput:focus {
  width: 250px;
  border: none;
  outline: none; }

.realm--page-title-bar {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 800;
  height: 68px;
  margin: 0;
  padding: 22px 30px;
  font-size: 16px;
  line-height: 32px;
  text-transform: uppercase;
  background-color: #ac5f0f;
  border: none;
  color: #fff; }
  @media screen and (min-width: 600px) {
    .realm--page-title-bar {
      transform: translateY(10px); } }
  .realm--page-title-bar a {
    color: #fff; }
    .realm--page-title-bar a:hover {
      color: #e2d3f3;
      text-decoration: none; }

.realm--add-plus {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 32px; }
  .realm--add-plus:hover {
    color: #bbe;
    transition: all 0.5s ease; }
  .realm--add-plus > i {
    margin-right: 0; }

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-width: 1048px) {
  .show-scrollbar header {
    width: calc(100% - 5px);
    left: 0; } }

.searchBlock {
  position: relative;
  background: rgba(255, 255, 255, 0.15);
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, serif;
  margin-left: 8px;
  margin-right: 16px;
  border-radius: 2px;
  display: inline-block;
  height: 25px; }

.searchIcon {
  width: 72px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  pointer-events: none;
  justify-content: center; }

.searchInputContainer {
  position: relative;
  display: inline-block;
  direction: ltr; }

.searchInput {
  width: 200px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font: inherit;
  color: inherit;
  border: 0;
  margin: 0;
  padding: 8px 8px 8px 72px;
  display: block;
  background: none;
  white-space: normal; }

.searchInput:focus {
  width: 250px;
  border: none;
  outline: none; }

.realm--button-group {
  width: 100%; }
  .realm--button-group button {
    width: 50%;
    border: 1px solid #c5c3bb; }
    .realm--button-group button:first-child {
      border-radius: 4px 0 0 4px; }
    .realm--button-group button:last-child {
      border-radius: 0 4px 4px 0; }

/* Non-global Components */
.realm--agentclient-card-content {
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #999; }

.realm--agentclient-card-brokerage {
  font-size: 14px; }

.realm--agentclient-card-info {
  font-size: 12px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #999; }

.realm--agentclient-card-listing-btn {
  display: inline-block;
  background: #c5c3bb;
  border-radius: 4px;
  padding: 9px 20px;
  color: #333 !important;
  text-transform: uppercase;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  transition: 350ms ease;
  margin: 10px; }
  .realm--agentclient-card-listing-btn:hover {
    color: #333 !important;
    background: #aeab9f;
    transition: 350ms ease; }

.realm--agentclient-card-actions {
  padding: 0 20px; }

.realm--cookie-warning {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #e3e3e3;
  padding: 2rem 4rem;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, serif;
  font-size: 16px;
  text-align: center;
  z-index: 100; }

.realm--cookie-warning-actions button {
  display: block;
  width: 280px;
  margin: 0 auto; }

.realm--loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  min-height: 70vh; }
  .realm--loading-container.fixed {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%; }
  .realm--loading-container.overlay {
    background: rgba(0, 0, 0, 0.55);
    z-index: 99999999; }
  .realm--loading-container .loading-indicator {
    margin: 0; }

.realm--loading-indicator {
  margin: 2em auto;
  position: relative;
  width: 64px;
  height: 64px; }
  .realm--loading-indicator div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px; }
    .realm--loading-indicator div:after {
      content: " ";
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: grey;
      margin: -3px 0 0 -3px; }
    .realm--loading-indicator div:nth-child(1) {
      animation-delay: -0.036s; }
      .realm--loading-indicator div:nth-child(1):after {
        top: 50px;
        left: 50px; }
    .realm--loading-indicator div:nth-child(2) {
      animation-delay: -0.072s; }
      .realm--loading-indicator div:nth-child(2):after {
        top: 54px;
        left: 45px; }
    .realm--loading-indicator div:nth-child(3) {
      animation-delay: -0.108s; }
      .realm--loading-indicator div:nth-child(3):after {
        top: 57px;
        left: 39px; }
    .realm--loading-indicator div:nth-child(4) {
      animation-delay: -0.144s; }
      .realm--loading-indicator div:nth-child(4):after {
        top: 58px;
        left: 32px; }
    .realm--loading-indicator div:nth-child(5) {
      animation-delay: -0.18s; }
      .realm--loading-indicator div:nth-child(5):after {
        top: 57px;
        left: 25px; }
    .realm--loading-indicator div:nth-child(6) {
      animation-delay: -0.216s; }
      .realm--loading-indicator div:nth-child(6):after {
        top: 54px;
        left: 19px; }
    .realm--loading-indicator div:nth-child(7) {
      animation-delay: -0.252s; }
      .realm--loading-indicator div:nth-child(7):after {
        top: 50px;
        left: 14px; }
    .realm--loading-indicator div:nth-child(8) {
      animation-delay: -0.288s; }
      .realm--loading-indicator div:nth-child(8):after {
        top: 45px;
        left: 10px; }

@keyframes lds-roller {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.realm--localizer {
  width: 300px;
  background-color: #585858;
  user-select: none; }

.realm--localizer-container {
  padding: 8px 12px; }

.realm--localizer-lang-area {
  background: #ebebeb;
  padding: 10px; }
  .realm--localizer-lang-area > div {
    font-size: 11px; }

.realm--localizer-lang-title {
  padding-bottom: 5px;
  margin: 0 5px 10px;
  font-weight: 600;
  color: #363636;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
  text-transform: uppercase; }

.realm--localizer-lang-text {
  display: block;
  margin-bottom: 6px;
  padding: 6px; }
  .realm--localizer-lang-text:hover {
    color: #555 !important; }

.realm--localizer-seperate-line {
  background: #ccc;
  height: 1px; }

.realm--selected-lang {
  background: #53573d;
  color: #fff !important;
  border-radius: 3px; }
  .realm--selected-lang:hover {
    color: #fff !important; }

.realm--selected-measure {
  background: #fff !important; }
  .realm--selected-measure span {
    color: #585858 !important; }

.realm--localizer-pop-btn {
  width: 50%;
  min-height: 26px !important;
  padding: 3px 10px !important;
  border: 1px solid #fff !important;
  border-radius: 0 3px 3px 0; }
  .realm--localizer-pop-btn span {
    font-size: 11px;
    color: #fff; }

.realm--localizer-pop-label {
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase; }
  .realm--localizer-pop-label .mr {
    margin-right: 20px; }

.realm--localizer-currency-selector {
  width: 150px;
  height: 30px;
  border-radius: 3px;
  float: right;
  font-weight: 400;
  font-size: 12px; }

.realm--localizer-pop-row {
  padding: 8px 0 !important;
  line-height: 26px !important; }

.realm--contact-link {
  float: right;
  color: #333;
  margin-top: 36px; }
  .realm--contact-link:hover {
    color: #666;
    text-decoration: none; }

.realm--house-card {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  background-color: rgba(61, 67, 98, 0.8);
  border-top: 1px solid #ac5f0f; }

.realm--house-card-image {
  flex-basis: 100%; }
  .realm--house-card-image img {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    background-size: 100% auto !important;
    background-repeat: no-repeat !important;
    transition: background-size 0.5s ease; }
    .realm--house-card-image img:hover {
      background-size: 110% auto !important;
      transition: background-size 0.5s ease; }

.realm--house-card-info {
  display: flex;
  justify-content: space-between;
  width: 100%; }

.realm--house-card-title {
  font-size: 14px;
  font-weight: 400;
  color: #333; }

.realm--house-card-address {
  font-size: 12px;
  color: #333; }

.realm--house-card-address-block {
  flex: 1 0 50%;
  padding: 12px;
  font-size: 12px;
  line-height: 1.3; }

.realm--house-card-broker-info {
  flex: 1 0 50%;
  padding: 12px;
  font-size: 12px;
  text-align: right; }

.realm--house-card-chart-block {
  float: right;
  margin-right: 2px; }

.realm--house-card-pie-block {
  flex: 1 0 25%;
  padding: 12px;
  font-size: 11px;
  text-align: right;
  overflow: hidden;
  max-height: 80px; }

.realm--house-card-pie-bar-area {
  position: relative; }
  .realm--house-card-pie-bar-area .recharts-wrapper {
    position: relative;
    width: 100px;
    height: 40px;
    left: 50%;
    margin-left: -53px; }

.realm--house-card-total {
  padding: 3px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px; }

.realm--house-card-detail-link {
  display: block;
  width: 100%;
  color: #fff; }
  .realm--house-card-detail-link:hover {
    color: #666;
    text-decoration: none; }

.realm--house-card-details-link {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #fff;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.75); }

.realm--agent-card {
  display: inline-block;
  width: 25%;
  height: 168px;
  min-width: 280px;
  margin-right: 2px;
  margin-top: 18px;
  padding: 12px;
  background-color: rgba(61, 67, 98, 0.8);
  border-top: 1px solid #ac5f0f;
  font-size: 18px;
  font-weight: 400; }

.realm--agent-details {
  display: inline-block;
  font-size: 14px;
  vertical-align: middle;
  max-width: 190px; }

.realm--agent-brokerage {
  font-size: 11px; }

.realm--card-content {
  margin-top: 12px; }

.realm--chart-block {
  float: left;
  margin-left: 4px;
  font-size: 14px; }

.realm--total {
  position: relative;
  top: 12px;
  float: right;
  margin-right: 16px;
  font-size: 14px;
  text-align: center; }

.realm--alert-card-title {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  padding-top: 12px; }

.realm--alert-card-date {
  text-align: center;
  font-size: 11px;
  font-weight: 300;
  padding-top: 6px;
  padding-bottom: 12px; }

.realm--alert-card-offered {
  text-align: center;
  line-height: 20px;
  margin: 12px auto;
  border-top: solid 1px #666;
  padding-top: 8px;
  font-weight: 300; }

.realm--alert-card-from-agent {
  padding-top: 8px;
  font-weight: 400; }

.realm--office-card {
  display: inline-block;
  min-width: 250px;
  margin-right: 12px; }

.realm--office-card-title {
  font-size: 14px; }

.realm--office-card-address {
  font-size: 12px; }

.ReactTable.-striped .rt-tr.-odd {
  padding-top: 8px;
  padding-left: 8px;
  background: rgba(61, 67, 98, 0.9) !important;
  margin-left: 20px;
  margin-right: 12px;
  border-left: #4e8dc3 3px solid;
  margin-bottom: 12px; }
  .ReactTable.-striped .rt-tr.-odd:hover {
    background: rgba(0, 0, 0, 0.2) !important; }

.ReactTable.-striped .rt-tr.-even {
  padding-top: 8px;
  padding-left: 8px;
  background: rgba(61, 67, 98, 0.8);
  margin-left: 20px;
  margin-right: 12px;
  border-left: #4e8dc3 3px solid;
  margin-bottom: 12px; }
  .ReactTable.-striped .rt-tr.-even:hover {
    background: rgba(0, 0, 0, 0.2) !important; }

/* on hover */
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(200, 30, 30, 0.4);
  background: #632703; }

/* column headers */
.ReactTable .rt-thead .rt-tr {
  color: gray;
  font-size: 12px;
  text-transform: uppercase;
  padding-bottom: 8px; }

/* turning diamond for subcomponents */
.ReactTable .rt-expander:after {
  border-top: 10px solid rgba(200, 200, 200, 0.8) !important; }

.ReactTable .-pagination input, .ReactTable .-pagination select {
  background: none; }

.ReactTable a {
  color: #fff;
  transition: all 0.5s ease; }

.ReactTable a:hover {
  color: #bbc;
  color: #ac5f0f !important;
  transition: all 0.5s ease; }

.ReactTable .-pagination .-btn {
  color: #eee; }

.ReactTable .-pagination .-pageJump input {
  width: 54px;
  text-align: center; }

.-totalPages {
  padding-left: 12px; }

.ReactTable .rt-thead .rt-th.-cursor-pointer:focus, .ReactTable .rt-thead .rt-td.-cursor-pointer:focus {
  outline: none; }

.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(90, 0, 0, 0.6); }

.ReactTable .rt-thead .rt-resizable-header-content {
  color: #aaa; }

.ReactTable .rt-thead .rt-th.-sort-asc .rt-resizable-header-content::after, .ReactTable .rt-thead .rt-td.-sort-asc {
  font-size: 10px;
  content: " ▲"; }

.ReactTable .rt-thead .rt-th.-sort-desc .rt-resizable-header-content::after, .ReactTable .rt-thead .rt-td.-sort-asc {
  font-size: 10px;
  content: " ▼"; }

.ReactTable .rt-thead.-filters input {
  background: #777;
  margin-left: 20px;
  color: white; }

/* ***************************************************
//
// for subcomponent editing for reacttable
//
// *************************************************** */
.editSection {
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 12px; }

.editSpecialties {
  margin-top: 12px;
  width: 250px; }

.editDescription {
  margin-top: 12px;
  width: 250px; }

.matchTotal {
  text-align: center;
  margin-right: 28px;
  padding-top: 14px;
  font-size: 20px; }

.realm--sorter-control {
  min-width: 170px;
  font-size: 30px; }

.tagStyle {
  font-size: 12px;
  background: #bbe;
  border-radius: 11px;
  transition: all 0.5s ease;
  width: fit-content;
  display: inline-block;
  margin-right: 8px; }
  .tagStyle:hover {
    background: #fff;
    transition: all 0.5s ease; }

.tagLabel {
  padding: 5px;
  padding-bottom: 4px;
  padding-left: 10px;
  color: #333;
  float: left; }

.tagAction {
  float: right;
  border-left: 1px solid #aba8a8;
  font-size: 16px;
  padding: 2px 4px;
  margin-left: 6px; }

.realm--tag-dialog-container {
  position: relative;
  color: #fff; }

.realm--no-tags-container {
  margin: 3rem 0;
  padding: 3rem;
  font-size: 1.75rem;
  text-align: center;
  background: #303d3b;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px; }
  .realm--no-tags-container p {
    margin: 0; }

.realm--edit-tags-toggle {
  display: block;
  width: 100%;
  padding: 1rem;
  text-align: center;
  text-transform: uppercase;
  background: #ac5f0f;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  transition: all 250ms ease-in-out; }
  .realm--edit-tags-toggle:hover {
    background: #95520d;
    color: #fff !important;
    transition: all 250ms ease-in-out; }

.realm--tag-list-label {
  font-size: 1.2rem;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(245, 224, 210, 0.45);
  margin-top: 4rem;
  padding-bottom: 1rem;
  color: #c5c3bb; }
  .realm--tag-list-label:first-child {
    margin-top: 0; }

.realm--tag-list-tag {
  display: block;
  width: 100%;
  font-size: 1.5rem;
  line-height: 1;
  margin: 0;
  padding-bottom: 1rem;
  padding: 2rem 0;
  border-bottom: 1px solid rgba(245, 224, 210, 0.45); }

.realm--tag-list-selectable-tag {
  display: block;
  width: 100%;
  font-size: 1.5rem;
  line-height: 1;
  margin: 0;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(245, 224, 210, 0.45); }
  .realm--tag-list-selectable-tag input[type="checkbox"] {
    opacity: 0; }
  .realm--tag-list-selectable-tag label {
    position: relative;
    width: 100%;
    color: #e8e5e2;
    transition: all 250ms ease-in-out;
    cursor: pointer; }
    .realm--tag-list-selectable-tag label:before {
      position: absolute;
      top: 0;
      right: 0;
      content: "";
      display: inline-block;
      height: 24px;
      width: 24px;
      border: 1px solid rgba(245, 224, 210, 0.7);
      border-radius: 50%;
      background: rgba(245, 224, 210, 0.25);
      transition: all 250ms ease-in-out; }
    .realm--tag-list-selectable-tag label:after {
      position: absolute;
      right: 9px;
      top: 9px;
      content: "";
      display: inline-block;
      height: 1px;
      width: 1px;
      border-left: 1px solid #ac5f0f;
      border-bottom: 1px solid #ac5f0f;
      transform: rotate(-45deg);
      transition: all 250ms ease-in-out; }
  .realm--tag-list-selectable-tag input[type="checkbox"] + label::after {
    transition: all 250ms ease-in-out; }
  .realm--tag-list-selectable-tag input[type="checkbox"]:checked + label::after {
    content: "";
    right: 5px;
    top: 6px;
    height: 8px;
    width: 13px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff; }
  .realm--tag-list-selectable-tag input[type="checkbox"]:checked + label::before {
    border: 1px solid #ac5f0f;
    background: #ac5f0f;
    transition: all 250ms ease-in-out; }

.realm--tag-action {
  float: right;
  margin-left: 6px;
  padding: 2px 4px;
  font-size: 16px;
  border-left: 1px solid #aba8a8; }

.realm--tag-label {
  float: left;
  padding: 5px 10px 4px 5px;
  color: #333; }

.realm--tag-style {
  display: inline-block;
  width: fit-content;
  margin-right: 8px;
  font-size: 12px;
  background: #bbe;
  border-radius: 11px;
  transition: all 0.5s ease; }
  .realm--tag-style:hover {
    background: #fff;
    transition: all 0.5s ease; }

button.edit, button.edit:focus {
  color: #fff !important;
  background-color: transparent !important;
  border: none; }

button.edit:hover {
  color: #88e !important; }

.json-input {
  height: 300px;
  width: 100%; }

.edit-modal {
  width: 400px;
  padding: 20px;
  color: #ccc;
  line-height: 20px; }

.input-error {
  background: pink;
  border: 1px solid red; }
