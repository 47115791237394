.realm {
  &--serified {
    font-family: $font-family-cormorant;
    text-transform: uppercase;
  }
  &--white-text {
    color: #fff !important;
    a {
      color: #fff !important;
      &:hover {
        color: #d3d3d3 !important;
      }
    }
  }
}

.text-centered {
  text-align: center!important;
}
