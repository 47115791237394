#realmDrawer {
  .sidebaricon {
    width: 24px;
    margin-left: 2px;
    margin-right: 24px;
    font-size: 24px;
    text-align: center;
    color: #ccc !important;
    &:hover {
      color: #fff !important;
    }
  }
  .subsidebaricon {
    margin-left: 15px;
    margin-right: 12px !important;
    font-size: 14px;
    color: #fff !important;
  }
  .submenuicon {
    margin-right: 8px;
  }
  .sidebarMainLink {
    font-size: 18px;
    color: #ccc;
    &:hover {
      color: #fff !important;
    }
  }
  span {
    color: #ccc !important;
  }
}

/* *********************************
sidebar (left) navigation and styles
only for views greater than 800px
******************************** */

@media screen and (min-width: 800px) {
  .sidebar-desktop {
    display: block;
    height: 100vH;
    width: 3.75rem;
    width: 200px;
    position: fixed;
    left: 0;
    top: 52px;
    box-shadow: 1px 0 4px 0 rgba(0,0,0,.25);
    box-shadow: 0.0625rem 0 0.25rem 0 rgba(0,0,0,.25);
    z-index: 50000;
    overflow: hidden;
    transition: width .5s ease;
  }

  .sidebar-nav {
    background: #000;
  }

  .sidebar-nav .panel-title {
    height: 22px;
  }

  .sidebarlink {
    padding-left: 16px;
    opacity:1;
    display:inline-block;
    width: auto;
    height: auto;
  }

  .sidebarlink-2nd {
    font-size:14px;
    line-height: 32px;
    padding-left: 6px;
  }

  .sidebarbadge {
    position: relative;
    font-size: 11px;
    padding: 0px 3px;
    top: -2px;
    left: 24px;
  }

  /*** showleft: open sidebar **********/
  #wrapper.showleft .sidebar-desktop {
    width: 200px;
  }

  #wrapper.showleft .navpanel {
    width: 210px;
    margin: auto;
    max-height: 100vh;
    overflow-y: auto;
  }


  /* *** minleft: when sidebar minimized but visible ***/

  #wrapper.minleft .sidebarlink {
  opacity: 0;
  white-space: nowrap;
  width:0px;
  }

  #wrapper.minleft.sidebarlink .sidebarlink-2nd {
    opacity: 0;
    white-space: nowrap;
    width: 0;
  }

  #wrapper.minleft .sidebarbadge {
    position: relative;
    top: -23px;
    left: 16px;
  }

  #wrapper.minleft .sidebar-desktop {
    width: 70px;
  }

  #wrapper.minleft .navpanel {
    width: 68px;
    margin-left: -4px;
    text-align: center;
  }

  /** hideleft: when sidebar hidden, but showing open widget **/
  #wrapper.hideleft .sidebar-desktop {
    width: 0;
  }

  #wrapper .sidebar-visible {
    margin-left: 220px;
  }

  #wrapper .sidebar-hidden {
    margin-left: 0px;
  }
}
